<template>
<div>
        <loading :active.sync="loading"></loading>
        <div class="row"><div class="col-md-12">
          <b-card style="max-width:860px">
            <div slot="header" data-v-step="1">
            <div class="row">
              <div class="col-md-8">
                <strong>Linha de Produtos</strong>
              </div>
              <div class="col-md-4" style="text-align:right;">
                <download-excel
                  class="btn btn-link" 
                  :data   = "matExcel"
                  name = "linhaprod.xls"
                   >
                  <CIcon name="cil-cloud-download" />&nbsp;<small>Exportar</small>
              </download-excel>
                <b-button variant="link" size="sm" @click="ajuda()"><CIcon name="cil-lightbulb" />&nbsp;<small>Ajuda</small></b-button>
              </div>
            </div>
          </div>
<b-tabs content-class="mt-3" v-model="ativa">
  <b-tab title="Ativas" active>
        <table class="table table-hover">
            <thead>
            <tr>
                <th style="width:10px"></th>
                <th data-v-step="1" style="width:200px">Linha de Produto</th>
                <th>Em uso</th>
            </tr>
            </thead>

            <tbody>
                <tr v-for="(materialItem, index) in pln" :key="index">
                    <td>
                      <b-form-checkbox  
                        v-model="pln[index].pln_active" 
                        switch 
                        @change.native="editar(index)"
                        size="sm" 
                      ></b-form-checkbox>
                    </td>
                    <td>
                      <b-form-input @blur.native="editar(index)" class="verde" v-model="pln[index].pln_name"></b-form-input>
                    </td>
                    <td>
                      <div v-if="emUso.indexOf(pln[index].pln_ID) >= 0">🔴</div>
                      <div v-else>⚪️</div>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td><b-form-input data-v-step="2" v-model="plnName"></b-form-input></td>
                    <td><button class="btn btn-success" :disabled="adicionou" @click="adicionar()">Adicionar</button></td>
                </tr>
            </tbody>
        </table>
  </b-tab>
<b-tab title="Inativas">
  <table class="table table-hover">
            <thead>
             <tr>
                <th style="width:10px"></th>
                <th style="width:200px">Linha de Produto</th>
                <th>Em uso</th>
            </tr>
            </thead>

            <tbody>
                <tr v-for="(materialItem, index) in plnoff" :key="index">
                    <td>
                      <b-form-checkbox
                        v-model="plnoff[index].pln_active" 
                        switch 
                        @change.native="reativarItem(plnoff[index].pln_ID)"
                        size="sm" 
                      ></b-form-checkbox>
                    </td>
                    <td>
                      <b-form-input class="amarelo" readonly v-model="plnoff[index].pln_name"></b-form-input>
                    </td>
                    <td>
                      <div v-if="emUso.indexOf(plnoff[index].pln_ID) >= 0">🔴</div>
                      <div v-else><b-button
                        @click="removerItem(index)" 
                        size="sm" 
                        style="background-color: white;"
                      >❌</b-button></div>
                    </td>
                </tr>
            </tbody>
        </table>
</b-tab>
</b-tabs>

          </b-card>
          <b-modal size="lg" id="ajuda" hide-footer title="Linha de Produtos">
      <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab no-body title="Tutorial" active>
            <b-card-text>
              <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/f2XdMkgKZKE?rel=0"
              allowfullscreen
            ></b-embed>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      </div>
    </b-modal>
        </div></div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'

export default {
  data () {
    return {
      stepsOptions: {
        labels: {
          buttonSkip: 'Sair da ajuda',
          buttonPrevious: 'Anterior',
          buttonNext: 'Próximo',
          buttonStop: 'OK!'
        }
      },
      steps: [
        {
          target: '[data-v-step="1"]',
          header: {
            title: 'Cadastro de Linha de Produtos',
          },
          content: `Aqui você cadastra as linhas de produtos. É uma forma de categorizar os seus produtos no Gera Preço.`
        },
        {
          target: '[data-v-step="2"]',
          content: 'Para adicionar uma nova linha, basta digitar o nome da sua linha de produtos e clicar em Adicionar.',
          params: {
            placement: 'top'
          }
        }
      ],
      ativa: 0,
      adicionou: false,
      materialItem: [],
      sessao: '',
      loading: false,
      mat: [],
      matoff: [],
      matExcel: [],
      plnName: '',
      pln: [],
      plnoff: [],
      emUso: [],
      dinheiro: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 2
      },
      dinheiro2: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 3
      },
      percentual: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: ' %',
        currencySymbolPlacement: 's',
        decimalPlaces: 2
      },
      decimal: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: '',
        decimalPlaces: 2
      }
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.loading = true
    this.verificaSessao()
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        if (!this.permissao('TABELAS_MATERIAPRIMA'))
        {
          this.$notify({
            type: 'danger',
            title: 'Sem permissão',
            text: 'Você não possui permissão para acessar esta área.'
          })
          return false
        }
        this.recarregar()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    emUsoCheck: function (i) {
      return this.campo('pro_Produto', 'pln_ID', '(pln_ID = ' + String(i) + ')').then(
        (c) => {
          return (c.length > 0 ? 0 : i)
        },
        (c) => {
          return 0
        }
      )
    },
    removerItem: function (i) {
      if (confirm('Remover esse item definitivamente?')) {
        this.remover('pro_Produto_Linha', 'pln_ID = ' + this.plnoff[i].pln_ID).then(
          (t) => {
            this.recarregar()
          },
          (t) => {
            this.recarregar()
          }
        )
      } else {
        return false
      }
    },
    reativarItem: function (i) {
      this.atualizar('pro_Produto_Linha', {resource: [ {pln_active: 1 } ]}, 'pln_ID = ' + String(i)).then(
        (r) => {
          this.ativa = 0
          this.recarregar()
        },
        (r) => {
          this.recarregar()
        }
      ) 
    },
    recarregar: function () {
      this.pln = []
      this.plnoff = []
      this.listar('pro_Produto_Linha', 'pln_name', '').then(
        (response) => {
          var prom = []
          for (var i = 0; i < response.length; i++) {
            if (response[i].pln_active) {
              this.pln.push({
                pln_ID: response[i].pln_ID,
                pln_name: response[i].pln_name,
                pln_active: response[i].pln_active,
                pln_datecreated: response[i].pln_datecreated
              })
            } else {
              this.plnoff.push({
                pln_ID: response[i].pln_ID,
                pln_name: response[i].pln_name,
                pln_active: response[i].pln_active,
                pln_datecreated: response[i].pln_datecreated
              })
            }
            this.matExcel.push({
              Ativo: response[i].pln_active ? 'Sim' : 'Não',
              Nome: response[i].pln_name,
              Data: response[i].pln_datecreated
            })
            prom.push(this.emUsoCheck(response[i].pln_ID))
          }
          Promise.all(prom).then(
            (p) => {
              this.emUso = p
              this.loading = false
            },
            (p) => {
              this.loading = false
            }
          )
        }, (response) => {
          this.loading = false
        }
      )
    },
    adicionar: function () {
      this.adicionou = true
      if (this.plnName === '') {
        this.$swal('Todos os campos são obrigatórios')
        this.adicionou = false
        return false
      }
      // Verifica se já existe
      this.campo('pro_Produto_Linha', 'pln_ID', '(pln_name = ' + this.plnName + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.plnName) {
            this.$swal('Já existe um registro com este nome: ID ' + response + '')
            this.adicionou = false
          } else {
            this.$swal('Erro ao inserir: ' + JSON.stringify(response))
            this.adicionou = false
          }
        }, (response) => {
          this.loading = true
          this.inserir('pro_Produto_Linha', {resource: [ {pln_name: this.plnName, pln_active: 1} ]}).then(
            (response) => {
              this.plnName = ''
              this.adicionou = false
              this.recarregar()
            },
            (response) => {
              this.$swal('Erro ao inserir: ' + JSON.stringify(response))
              this.adicionou = false
              this.loading = false
            }
          )
        }
      )
    },
    editar: function (index) {
      if (this.pln[index].pln_name === '') {
        this.$swal('Todos os campos são obrigatórios')
        return false
      }
      // Verifica se já existe
      this.campo('pro_Produto_Linha', 'pln_ID', '(pln_name = ' + this.pln[index].pln_name + ') AND (pln_ID != ' + this.pln[index].pln_ID + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.plnSigla) {
            this.$swal('Já existe um registro com este nome: ID ' + response + '')
          } else {
            this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
          }
        }, (response) => {
          this.loading = true
          this.atualizar('pro_Produto_Linha', {resource: [ {pln_name: this.pln[index].pln_name, pln_active: this.pln[index].pln_active} ]}, 'pln_ID = ' + this.pln[index].pln_ID).then(
            (response) => {
              this.verificaSessao()
            },
            (response) => {
              this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
              this.loading = false
            }
          )
        }
      )
    },
    ajuda: function() {
      this.$bvModal.show('ajuda')
    }
  },
  components: {
    Loading,
    VueAutonumeric
  }
}
</script>